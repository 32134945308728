import Image from "next/image";

import Sprite from "@/components/atoms/Sprite";
import SaveJobButton from "@/components/molecules/SaveJobButton";
import { Share } from "@/components/organisms/Share";
import {
  ImageFieldsFragment,
  ImageStyleAvailable,
} from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";
import { getVariation } from "@/helpers/image";

interface HeroProps {
  className?: string;
  image?: {
    url: string;
  };
  media?: ImageFieldsFragment;
  noSprite?: boolean;
  saveJobId?: string;
  share?: boolean;
  small?: boolean;
  title?: string;
  titleClassName?: string;
  titleLeft?: boolean;
}

export const Hero = ({
  media,
  image,
  title,
  titleClassName,
  className,
  share,
  small,
  titleLeft,
  noSprite,
  saveJobId,
}: HeroProps) => {
  if (!image) {
    image = media?.variations
      ? getVariation(media.variations, ImageStyleAvailable.Hero)
      : undefined;
  }

  return (
    <div
      className={cn(
        "default-hero relative z-0 mb-10 mt-0 flex min-h-[215px] items-end justify-center px-4 pt-10 md:min-h-[400px] lg:mb-24 xl:min-h-[440px] 2xl:min-h-[550px]",
        !image && "relative overflow-hidden bg-yellow-600",
        small ? "min-h-[150px] md:min-h-[300px] xl:min-h-[300px]" : "",
        className,
      )}
      style={image && { backgroundColor: "rgba(0, 0, 0, 0.3)" }}
    >
      {image ? (
        <Image
          className="inset-0 -z-1 h-full w-full object-cover mix-blend-multiply xl:absolute"
          src={image.url}
          alt={media?.alt ?? title ?? ""}
          fill
          loading={"eager"}
          priority
        />
      ) : (
        <>
          {!noSprite && (
            <Sprite className="right-0 top-1/2 mt-12 w-[517px] -translate-y-1/2 translate-x-[66px] transition-none" />
          )}
        </>
      )}

      <div
        className={cn(
          "container relative z-1 flex flex-col gap-8",
          !titleLeft && "md:items-center",
        )}
      >
        <div className="mx-auto flex items-center justify-center gap-2 empty:hidden">
          {saveJobId && (
            <SaveJobButton
              jobId={saveJobId}
              className="top-0 m-0 flex aspect-square w-12 items-center justify-center rounded-full border-2 border-white/50 p-0 !text-white hover:scale-100 hover:border-black hover:!text-black lg:w-16"
            />
          )}

          {share && <Share title={title ?? ""} className="" />}
        </div>

        {title && (
          <h1
            className={cn(
              "break-words text-center lg:!mb-20",
              image && "text-white",
              titleClassName,
            )}
          >
            {title}
          </h1>
        )}
      </div>
    </div>
  );
};
